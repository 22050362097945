import { ColumnsType } from "antd/es/table";
import {
  CustomerInfoTabIcon,
  CustomerSpecificTabIcon,
  ProductsTabIcon,
  TicketsTabIcon,
  UsersTabIcon,
} from "../../components/Icons/CustomerIcons";
import { SortIcon } from "../../components/Icons/Icons";
import StatusTag from "../../components/StatusTag/StatusTag";
import { ICustomerUserTable } from "../../interface/CustomerDetails";
import { SORT_ORDER } from "../../utils/constants/common";
import { capitalizeFirstLetter } from "../../utils/utils";
import CustomerInfo from "./CustomerInfo/CustomerInfo";
import CustomerSpecific from "../../components/CustomerSpecific/CustomerSpecific";
import ProductsList from "./ProductsTab/ProductsList";
import Tickets from "./Tickets/Tickets";
import Users from "./UsersTab/Users";
import { StatusColumn } from "../../utils/TableStyle";
import UserTableAction from "./UsersTab/UserTableAction";
import { MenuProps } from "antd";

export enum USER_LIST_TYPE {
  PRAGMATIC_USERS = "pragmatic_users",
  CUSTOMERS = "customers",
}

export enum ASSIGNED_USER_ROLE {
  ADMIN = "admin",
  VIEWER = "viewer",
}

const items: MenuProps["items"] = [
  {
    key: "unassign",
    label: "Unassign user",
    danger: true,
  },
];

const customerUserActionItems: MenuProps["items"] = [
  {
    key: "delete",
    label: "Delete user",
    danger: true,
  },
];

export const customerDetailsPath = "/home/customers/detail/";

export const customerDetailsTabs: any = [
  {
    key: "products",
    label: "Products and Platforms",
    children: <ProductsList />,
    icon: <ProductsTabIcon />,
  },
  {
    key: "users",
    label: "Users",
    children: <Users />,
    icon: <UsersTabIcon />,
  },
  {
    key: "customer-specific",
    label: "Customer Specific",
    children: <CustomerSpecific />,
    icon: <CustomerSpecificTabIcon />,
  },
  {
    key: "tickets",
    label: "Tickets",
    children: <Tickets />,
    icon: <TicketsTabIcon />,
  },
  {
    key: "customer-info",
    label: "Customer Info",
    children: <CustomerInfo />,
    icon: <CustomerInfoTabIcon />,
  },
];

export const filteredTabs = customerDetailsTabs.filter(
  (tab: any) => tab.key !== "customer-specific"
);

export const productColumns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "40%",
    render: (_: any, { name }: any) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "30%",
    render: (_: any, { type }: any) => (
      <>
        <StatusTag content={type} />
      </>
    ),
  },
  {
    title: "Assigned Date",
    dataIndex: "added_date",
    width: "30%",
  },
];

export const pragmaUserColumns: ColumnsType<ICustomerUserTable> = [
  {
    title: "Name",
    dataIndex: "name",
    width: "20%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Role",
    dataIndex: "role",
    width: "15%",
    render: (_, { role }) => (
      <>
        <StatusTag content={role} />
      </>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "25%",
  },
  {
    title: "Assigned Date",
    dataIndex: "added_date",
    width: "25%",
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    width: "15%",
    className: "status",
    sortIcon: ({ sortOrder }) => {
      return sortOrder === SORT_ORDER.asc ? (
        <SortIcon />
      ) : (
        <span className="rotate-arrow">
          <SortIcon />
        </span>
      );
    },
    render: (_, record) => (
      <StatusColumn>
        <StatusTag content={record?.status} />
        <UserTableAction items={items} data={record} />
      </StatusColumn>
    ),
  },
];

export const customerUserColumns: ColumnsType<ICustomerUserTable> = [
  {
    title: "Name",
    dataIndex: "name",
    width: "20%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Role",
    dataIndex: "role",
    width: "15%",
    render: (_, { role }) => (
      <>
        <StatusTag content={role} />
      </>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "25%",
  },
  {
    title: "Added Date",
    dataIndex: "added_date",
    width: "25%",
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a: any, b: any): any => a.status.length - b.status.length,
    width: "15%",
    className: "status",
    sortIcon: ({ sortOrder }) => {
      return sortOrder === SORT_ORDER.asc ? (
        <SortIcon />
      ) : (
        <span className="rotate-arrow">
          <SortIcon />
        </span>
      );
    },
    render: (_, record) => (
      <StatusColumn>
        <StatusTag content={record?.status} />
        <UserTableAction
          isCustomerAction={true}
          items={customerUserActionItems}
          data={record}
        />
      </StatusColumn>
    ),
  },
];
