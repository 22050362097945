import { TreeWrapper } from "../../TreeView/TreeViewStyled";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { INodes } from "../../../interface/ProductDetails";
import TreeView from "../../TreeView/TreeView";

const CustomerFolderTree = () => {
  const { vertex_nodes } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  return (
    <TreeWrapper>
      {vertex_nodes.map((folder: INodes) => (
        <TreeView
          key={folder?.id}
          type="customerSpecific"
          nodes={{
            ...folder,
          }}
          children={folder?.sub_nodes || []}
        />
      ))}
    </TreeWrapper>
  );
};

export default CustomerFolderTree;
