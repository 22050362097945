import React, { useEffect } from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useLocation } from "react-router-dom";
import { INodes } from "../../interface/ProductDetails";
import { GET } from "../../service/api";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import CustomerSpecific from "../../components/CustomerSpecific/CustomerSpecific";
import { updateToggleLoader } from "../../store/features/Common.slice";

export enum PATHNAMES {
  documentation = "documentation",
  design = "designs",
  testData = "test_data",
}

const CustomerUserFolderTree = () => {
  const { customer_specific_nodes } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    customer_specific_nodes?.length && _createCustomerNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_specific_nodes?.length, location?.pathname]);

  const _createCustomerNodes = () => {
    dispatch(updateToggleLoader(true));
    if (location?.pathname?.includes(PATHNAMES.documentation)) {
      _fetchNodeDetails(PATHNAMES.documentation);
    } else if (location?.pathname?.includes(PATHNAMES.design)) {
      _fetchNodeDetails(PATHNAMES.design);
    } else {
      _fetchNodeDetails("test data");
    }
  };

  const _fetchNodeDetails = (name: string) => {
    const node = (customer_specific_nodes || []).find(
      (node: INodes) => node?.name?.toLowerCase() === name
    );
    const actionType = name === "test data" ? "test_data" : name;
    node &&
      dispatch(
        GET(
          `customerSpecific/${actionType}/detail`,
          `${ApiUrl.customerSpecificNodes}${node?.id}/`
        )()
      ).then(() => dispatch(updateToggleLoader(false)));
  };

  return (
    <>
      <ContentHeader />
      <CustomerSpecific isIndividual={true} />
    </>
  );
};

export default CustomerUserFolderTree;
