import { Tooltip } from "antd";
import styled from "styled-components";

const TagWrapper = styled.div<{ type: string; $circular: number }>`
  .tag {
    align-items: center;
    width: fit-content;
    height: 2.9rem;
    background: ${(props) =>
      `var(--${props?.type?.replace(" ", "-")?.toUpperCase()})`};
    padding: ${(props) => (props.$circular ? "7px 6px" : "4px 6px")};
    color: ${(props) =>
      `var(--TEXT-${props?.type?.replace(" ", "-")?.toUpperCase()})`};
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: ${(props) => (props.$circular ? "50%" : "6px")};
  }
`;

const StatusTag = ({
  content,
  tooltip,
  circular = false,
}: {
  content: string;
  tooltip?: string;
  circular?: boolean;
}) => {
  return (
    <TagWrapper type={content} $circular={+circular}>
      <Tooltip placement="top" title={tooltip ?? ""}>
        <div className="tag d-flex">{content}</div>
      </Tooltip>
    </TagWrapper>
  );
};

export default StatusTag;
