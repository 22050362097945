import { useState } from "react";
import AddModal from "../AddModal/AddModal";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  renameNode,
  setConfirmRenameFolder,
  setOpenRenameFolderModal,
} from "../../store/features/FolderTree";
import {
  CUSTOMER_PATHS,
  MORE_ACTIONS,
  PRODUCT_TYPES,
  STATUS,
} from "../../utils/constants/common";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { PATCH } from "../../service/api";
import { triggerToastMessage } from "../../utils/utils";
import {
  reloadCustomerDocTable,
  setSelectedRecord,
} from "../../store/features/CustomerDetails.slice";
import { reloadTable } from "../../store/features/Product.slice";
import { setReloadTable } from "../../store/features/FolderTree";
import { useLocation } from "react-router-dom";

const RenameNode = ({
  open,
  type = "Folder",
}: {
  open: boolean;
  type?: string;
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { openRenameFolderModal } = useAppSelector(
    (state) => state.FolderTreeReducer
  );
  const { selectedRecord } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const { selected_action_item } = useAppSelector(
    (state) => state.ProductReducer
  );

  const isFolder = type.toLowerCase() === MORE_ACTIONS.FOLDER;
  const isFile = type.toLowerCase() === MORE_ACTIONS.FILE;

  const updateFolder = ({ name }: { name: string }) => {
    const data = {
      node: selectedRecord?.id,
      new_name: name,
    };
    if (isFolder) {
      dispatch(renameNode({ ...data }));
      dispatch(setConfirmRenameFolder(true));
      return;
    } else if (isFile) {
      _updateFilename(data);
      return;
    }
    _update(data);
  };

  const reset = () => {
    dispatch(setOpenRenameFolderModal({ open: false, isFolder: true }));
    dispatch(setSelectedRecord({}));
    setIsSuccess(false);
  };

  const _updateFilename = (payload: { node: string; new_name: string }) => {
    dispatch(
      PATCH("nodes/rename_file", `${ApiUrl.renameFile}${payload.node}/`, {
        ...payload,
      })()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        dispatch(setReloadTable(true));
        CUSTOMER_PATHS?.some((path: string) =>
          location?.pathname?.includes(path)
        ) && dispatch(reloadCustomerDocTable(true));
        dispatch(setOpenRenameFolderModal({ open: false, isFolder: true }));
        triggerToastMessage(
          `${res?.payload?.data?.message}`,
          "success",
          `You renamed ${type.toLowerCase()} '${selectedRecord?.name}' to '${
            payload?.new_name
          }'`
        );
      }
    });
  };

  const _update = (payload: { node: string; new_name: string }) => {
    dispatch(
      PATCH(
        "nodes/rename",
        `${ApiUrl.productList}${payload.node}/${ApiUrl.renameFolder}`,
        {
          ...payload,
        }
      )()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        if (
          type.toLowerCase() === PRODUCT_TYPES.platform ||
          type.toLowerCase() === PRODUCT_TYPES.product
        )
          dispatch(reloadTable(true));
        const { data } = res?.payload?.data;
        dispatch(setOpenRenameFolderModal({ open: false, isFolder: true }));
        triggerToastMessage(
          `${selected_action_item?.type} renamed`,
          "success",
          `You renamed ${selected_action_item?.type} '${selected_action_item?.name}' to '${data}'`
        );
      }
    });
  };

  return (
    <AddModal
      type="rename"
      title={`Rename ${type}`}
      placeholder="Enter name"
      add={updateFolder}
      reset={reset}
      open={openRenameFolderModal}
      isSuccess={isSuccess}
      data={selectedRecord}
      maxlength={50}
    />
  );
};

export default RenameNode;
