import { Col, Empty, Table, TableProps } from "antd";
import { LoaderLineComponent } from "../../Loader/Loader";
import { IProductTable } from "../../../interface/Product";
import { ColumnsType } from "antd/es/table";
import TablePagination from "../../TablePagination/TablePagination";
import Search from "../../Search/Search";
import { Wrapper } from "../../../utils/TableStyle";
import EmptyData from "../../EmptyData/EmptyData";
import { ProductTableType } from "../productTableTypes";
import { ContentWrapper } from "../../../pages/CustomerDetails/CustomerDetailsStyled";
import { capitalizeFirstLetter, isIndividualUser } from "../../../utils/utils";
import { useLocation } from "react-router-dom";
import { ANTD_TABLE_LOCALE } from "../../../utils/constants/common";
import productsEmpty from "../../../assets/images/illustration/no-products.png";

interface ProductListTableStates {
  table_loader: boolean;
  total_data: number;
  currentPage: number;
  search_param: string;
}

interface IProductListTableParams {
  changeTableProps: TableProps<IProductTable>["onChange"];
  onRowClick: Function;
  onPageChange: Function;
  onSearchChange: Function;
  columns: ColumnsType;
  list: IProductTable[];
  states: ProductListTableStates;
  options: ProductTableType;
  type: string;
}

const ProductListTable = ({
  changeTableProps,
  onRowClick,
  onPageChange,
  onSearchChange,
  columns,
  list,
  states,
  options,
  type,
}: IProductListTableParams) => {
  const loadIcon = <LoaderLineComponent />;
  const location = useLocation();
  const identifier = location.pathname.split("/")[2];
  const placeholder = isIndividualUser()
    ? `Search ${capitalizeFirstLetter(identifier)}`
    : "Search Products/Platforms";

  const SearchElem = (
    <Col xs={24} md={7}>
      <Search
        placeholder={placeholder}
        searchData={onSearchChange}
        searchVal={states.search_param}
      />
    </Col>
  );

  return (
    <>
      {list?.length ? (
        <Wrapper>
          {options.showSearch && SearchElem}
          <Col xs={24}>
            <Table
              locale={ANTD_TABLE_LOCALE}
              size="middle"
              onChange={changeTableProps}
              scroll={{ x: true }}
              className="custom-table"
              onRow={(record) => {
                return {
                  onClick: () => onRowClick(record),
                };
              }}
              columns={columns}
              dataSource={list}
              pagination={false}
              rowKey="id"
              loading={{
                indicator: loadIcon,
                spinning: states.table_loader,
              }}
            />
          </Col>
          {options.showPagination && (
            <>
              <div className="pagination d-flex">
                <span className="item-count">
                  Showing {list?.length} item(s) out of&nbsp;
                  {states.total_data} results found.
                </span>

                <TablePagination
                  total={states.total_data}
                  onChange={(page) => onPageChange(page)}
                  current={states.currentPage}
                />
              </div>
            </>
          )}
        </Wrapper>
      ) : states.table_loader ? (
        loadIcon
      ) : states.search_param ? (
        <>
          {options.showSearch && SearchElem}
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </>
      ) : (
        <ContentWrapper>
          <EmptyData
            type="product/platform"
            subTextType={type === "CUSTOMER_PRODUCTS" ? "Assign" : "Add"}
            image={productsEmpty}
          />
        </ContentWrapper>
      )}
    </>
  );
};

export default ProductListTable;
