import ActionMenu from "../ActionMenu/ActionMenu";
import { MenuProps, Tooltip } from "antd";
import { useAppDispatch } from "../../hooks/reduxHooks";
import {
  setSelectedActionItem,
  setProductActionModalSt,
} from "../../store/features/Product.slice";
import {
  _checkActionPermission,
  checkAssignedRole,
  isSuperAdminUser,
} from "../../utils/utils";
import { MORE_ACTIONS, PRODUCT_TYPES } from "../../utils/constants/common";
import { setOpenRenameFolderModal } from "../../store/features/FolderTree";

const ProductTableAction = ({
  data,
  items,
}: {
  data: any;
  items: MenuProps["items"];
}) => {
  const dispatch = useAppDispatch();
  const isSuperAdmin = isSuperAdminUser();
  const assignedRole = checkAssignedRole();
  const hasPermission = _checkActionPermission(assignedRole, "");

  const handleMenuClick = (item: { key: string }) => {
    dispatch(setSelectedActionItem(data));
    switch (item.key) {
      case PRODUCT_TYPES.product:
      case PRODUCT_TYPES.platform:
        dispatch(setProductActionModalSt(true));
        break;
      case MORE_ACTIONS.RENAME:
        dispatch(setOpenRenameFolderModal({ open: true, isFolder: true }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      {(isSuperAdmin || hasPermission) && (
        <Tooltip placement="top" title="More Actions">
          <div className="actions">
            <ActionMenu
              data={data}
              items={items}
              handleAction={(item: any) => handleMenuClick(item)}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default ProductTableAction;
