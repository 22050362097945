import { Breadcrumb } from "antd";
import { RightArrow } from "../Icons/Icons";
import { capitalizeFirstLetter } from "../../utils/utils";
import { setActiveNode } from "../../store/features/FolderTree";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";

const BreadcrumbNodes = () => {
  const dispatch = useAppDispatch();
  const { breadcrumbs, breadcrumbNodes } = useAppSelector(
    (state) => state.FolderTreeReducer
  );

  const items = breadcrumbs?.map(({ title }: { title: string }) => ({
    key: title,
    title: capitalizeFirstLetter(title),
    onClick: () => selectBreadcrumbNode(title),
  }));

  const selectBreadcrumbNode = (item: string) => {
    const selectedBreadcrumb = (breadcrumbNodes || []).find(
      ({ name }: { name: string }) => item === name
    );
    if (selectedBreadcrumb?.name) {
      dispatch(setActiveNode({ ...selectedBreadcrumb }));
    }
  };

  return <Breadcrumb separator={<RightArrow />} items={items} />;
};

export default BreadcrumbNodes;
