import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDeletedItemsReducer } from "../../interface/DeletedItems";
import { GET } from "../../service/api";

const initialState: IDeletedItemsReducer = {
  deletedCustomers: [],
  deletedNodes: [],
  deletedUsers: [],
  deletedDocuments: [],
  selectedFilterValue: "",
  openPermanantDeleteModal: false,
  openRestoreItemModal: false,
  selectedAction: "",
  reloadDelTable: false,
  totalData: 0,
  page: 1,
  pageSize: 10,
};
const RecentlyDeletedSlice = createSlice({
  name: "recently_deletd_reducer",
  initialState,
  reducers: {
    setSelectedFilterValue: (state, action: PayloadAction<string>) => {
      state.selectedFilterValue = action.payload;
    },
    setRestoreModalOpenSt: (state, action: PayloadAction<boolean>) => {
      state.openRestoreItemModal = action.payload;
    },
    setPermanantDeleteModalSt: (state, action: PayloadAction<boolean>) => {
      state.openPermanantDeleteModal = action.payload;
    },
    setSelectedAction: (state, action: PayloadAction<string>) => {
      state.selectedAction = action.payload;
    },
    setReloadDelTable: (state, action: PayloadAction<boolean>) => {
      state.reloadDelTable = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetRecentlyDelReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("fetch/deleted/customers", "").fulfilled,
      (state, action) => {
        const { results, total_count } = action?.payload?.data;
        state.deletedCustomers = results || [];
        state.totalData = total_count;
        state.reloadDelTable = false;
      }
    );
    builder.addCase(
      GET("fetch/deleted/users", "").fulfilled,
      (state, action) => {
        const { results, total_count } = action?.payload?.data;
        state.deletedUsers = results || [];
        state.totalData = total_count;
        state.reloadDelTable = false;
      }
    );
    builder.addCase(
      GET("fetch/deleted/nodes", "").fulfilled,
      (state, action) => {
        const { results, total_count } = action?.payload?.data;
        state.totalData = total_count;
        state.deletedNodes = results || [];
        state.reloadDelTable = false;
      }
    );
    builder.addCase(
      GET("fetch/deleted/documents", "").fulfilled,
      (state, action) => {
        const { results, total_count } = action?.payload?.data;
        state.totalData = total_count;
        state.deletedDocuments = results || [];
        state.reloadDelTable = false;
      }
    );
    builder.addCase(GET("fetch/deleted/customers", "").rejected, (state) => {
      state.deletedCustomers = [];
      state.reloadDelTable = false;
    });
    builder.addCase(GET("fetch/deleted/users", "").rejected, (state) => {
      state.deletedUsers = [];
      state.reloadDelTable = false;
    });
    builder.addCase(GET("fetch/deleted/nodes", "").rejected, (state) => {
      state.deletedNodes = [];
      state.reloadDelTable = false;
    });
    builder.addCase(GET("fetch/deleted/documents", "").rejected, (state) => {
      state.deletedDocuments = [];
      state.reloadDelTable = false;
    });
  },
});
export default RecentlyDeletedSlice.reducer;
export const {
  setSelectedFilterValue,
  setRestoreModalOpenSt,
  setPermanantDeleteModalSt,
  setSelectedAction,
  setReloadDelTable,
  updatePage,
  resetRecentlyDelReducer,
} = RecentlyDeletedSlice.actions;
