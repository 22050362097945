import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ICustomerActivityLog,
  ICustomerInfoReducer,
} from "../../interface/CustomerDetails";
import { GET } from "../../service/api";

const initialState: ICustomerInfoReducer = {
  activityLogParams: {
    page: 1,
    page_size: 25,
  },
  activity_log: [{ id: "", date: "", activity: "", user: "", role: "" }],
  activity_list_loader: true,
  reload_list: true,
  total_data: 0,
  total_pages: 0,
};

const CustomerInfoSlice = createSlice({
  name: "customer_info_reducer",
  initialState: initialState,
  reducers: {
    setActivityLog: (state, action: PayloadAction<ICustomerActivityLog[]>) => {
      state.activity_log = action.payload;
    },
    setActivityPage: (state, action: PayloadAction<number>) => {
      state.activityLogParams.page = action.payload;
      state.reload_list = true;
    },
    setActivityPageSize: (state, action: PayloadAction<number>) => {
      state.activityLogParams.page_size = action.payload;
      state.reload_list = true;
    },
    reloadList: (state, action: PayloadAction<boolean>) => {
      state.reload_list = action.payload;
    },
    setActivityListLoader: (state, action: PayloadAction<boolean>) => {
      state.activity_list_loader = action.payload;
    },
    resetCustomerInfoReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      GET("activity-logs/list", "").fulfilled,
      (state, action) => {
        const { results, total_count, total_pages } = action.payload.data;
        state.activity_log = results;
        state.total_pages = total_pages;
        state.total_data = total_count;
        state.activity_list_loader = false;
        state.reload_list = false;
      }
    );
    // builder.addCase(GET("activity-logs/list", "").rejected, (state) => {
    //   state.activity_log = [];
    //   state.total_pages = 0;
    //   state.total_data = 0;
    // });
  },
});

export default CustomerInfoSlice.reducer;
export const {
  setActivityLog,
  setActivityPage,
  reloadList,
  resetCustomerInfoReducer,
  setActivityPageSize,
  setActivityListLoader,
} = CustomerInfoSlice.actions;
