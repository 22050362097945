import React from "react";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import { MenuProps } from "antd";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import {
  setCustomerUserActionVariables,
  setSelectedRecord,
} from "../../../store/features/CustomerDetails.slice";
import { checkAssignedRole, isSuperAdminUser } from "../../../utils/utils";
import { USER_ROLES } from "../../../utils/constants/common";

const UserTableAction = ({
  data,
  items,
  isCustomerAction = false,
}: {
  data: any;
  items: MenuProps["items"];
  isCustomerAction?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const isSuperAdmin = isSuperAdminUser();
  const assignedRole = checkAssignedRole();

  const handleMenuClick = (item: { key: string; label: string }) => {
    dispatch(setSelectedRecord(data));
    dispatch(
      setCustomerUserActionVariables({
        open: true,
        action: { key: item?.key, label: item?.label },
      })
    );
  };

  return (
    <>
      {(isSuperAdmin ||
        (isCustomerAction && assignedRole === USER_ROLES.ADMIN)) && (
        <div className="actions">
          <ActionMenu
            items={items}
            handleAction={(item: any) => handleMenuClick(item)}
          />
        </div>
      )}
    </>
  );
};

export default UserTableAction;
