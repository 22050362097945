import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";
import { StoreStatus } from "../interface/Auth";
import { isAdmin, isSuperAdminUser, isViewerUser } from "./utils";
import { USER_ROLES } from "./constants/common";

const PrivateRoute = ({
  roles = [],
  children,
}: {
  roles?: string[];
  children: JSX.Element;
}) => {
  const { isAuthenticated, store_status } = useAppSelector(
    (state) => state.AuthReducer
  );
  const userRole = isSuperAdminUser()
    ? USER_ROLES.SUPER_ADMIN
    : isAdmin()
    ? USER_ROLES.ADMIN
    : isViewerUser()
    ? USER_ROLES.VIEWER
    : USER_ROLES.INDIVIDUAL;

  if (!isAuthenticated && store_status !== StoreStatus.Loading) {
    return <Navigate to="/" />;
  }
  if (
    isAuthenticated &&
    roles?.length &&
    !roles?.some((role: string) => role === userRole)
  ) {
    return <Navigate to="/access-denied" />;
  }
  return children;
};

export default PrivateRoute;
