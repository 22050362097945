export const VERSION = "v1/";

export const ApiUrl = {
  login: "auth/login/",
  otp: "users/totp/login/",
  createPassword: "users/set-password/",
  generateQRCode: "users/totp/create/",
  downloadCodes: "users/static/file/",
  getRecoveryTokens: "users/static/create/",
  validateToken: "users/validate-token/",
  logout: "auth/logout/",
  customerList: "customers/",
  recoveryCodeLogin: "users/static/login/",
  productList: "nodes/pragmatic/",
  userList: "users/",
  roles: "roles/",
  addCustomerUser: "users/customer-users/",
  deleteCustomerUser: "users/customer-users/",
  assignPragmaticUser: "users/roles/",
  customerProductList: "customers/{id}/nodes/",
  customerSpecificNodes: "nodes/customer-specific/",
  assignProducts: "customers/assign/",
  uploadDocument: "nodes/upload-file/",
  downloadDocument: "nodes/download-file/",
  downloadFolder: "nodes/download-folder/",

  deleteFile: "nodes/delete-file/",
  tickets: "support-tickets/",
  blockedIps: "auth/blocked-ips/",
  unblockIP: "auth/unblock-ip/",
  supportTickets: "support-tickets/",
  activityLogs: "activity-logs/",
  renameFolder: "rename/",
  forgotPassword: "users/forgot-password/",
  resetPassword: "users/reset-password/",
  socketConnection: "auth/websocket-login/",
  listDeletedCustomers: "customers/list-deleted-customers/",
  listDeletedProducts: "nodes/deleted-items/list-deleted-product-platforms/",
  listDeletedUsers: "users/deleted-users/",
  listDeletedFiles: "nodes/deleted-items/list-deleted-file-folders/",
  changePassword: "users/change-password/",
  restoreCustomers: "customers/:id/restore-customer/",
  permanantCustomerDelete: "customers/:id/delete-customer-permanently/",
  restoreUser: "users/deleted-users/:id/restore/",
  permanantUserDelete: "users/deleted-users/:id/permanent-delete/",
  restoreNode: "/nodes/deleted-items/:id/restore-product-platforms/",
  permanantNodeDelete:
    "nodes/deleted-items/:id/delete-product-platforms-permanently/",
  restoreDocuments:
    "nodes/deleted-items/:id/restore-deleted-file-folders/?type=:type",
  permananatDocDelete:
    "nodes/deleted-items/:id/delete-file-folders-permanently/?type=:type",
  renameFile: "nodes/file-rename/",
  deleteAccount: "/self-delete-account/",
  verifyPasswordMFAUpdate: "users/totp/update/",
  verifyOTPMFAUpdate: "users/totp/login-update/",
};
