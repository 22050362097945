import { useEffect } from "react";
import EmptyData from "../EmptyData/EmptyData";
import { CustomerSpecificWrapper } from "../../pages/CustomerDetails/CustomerDetailsStyled";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  setBreadCrumb,
  setBreadCrumbNodes,
  setConfirmFolderDelete,
  setCustomerActiveNode,
  setOpenDeleteFolderModalSt,
} from "../../store/features/FolderTree";
import { DELETE, GET } from "../../service/api";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { useLocation, useParams } from "react-router-dom";
import UploadDocument from "../UploadDocument/UploadDocument";
import { updatePageLoader } from "../../store/features/Common.slice";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { triggerToastMessage } from "../../utils/utils";
import {
  CONFIRM_MODAL_MESSAGES,
  CUSTOMER_SPECIFIC_EMPTY_MSG,
} from "../../utils/constants/messages";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import RenameNode from "../RenameNode/RenameNode";
import {
  reloadCustomerDocTable,
  setCustomerSpecificNodes,
  setOpenAddFolderModal,
} from "../../store/features/CustomerDetails.slice";
import { STATUS } from "../../utils/constants/common";
import { LoaderLineComponent } from "../Loader/Loader";
import AddFolder from "../../pages/ProductDetail/AddFolder/AddFolder";
import { INodes } from "../../interface/ProductDetails";
import { PATHNAMES } from "../../pages/CustomerUserFolderTree/CustomerUserFolderTree";
import CustomerSpecificTreeWrapper from "./CustomerSpecificWrapper/CustomerSpecificTreeWrapper";
import { ICustomerTable } from "../../interface/Customer";

const CustomerSpecific = ({ isIndividual = false }) => {
  let customerData = {} as ICustomerTable;
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location = useLocation();
  const { getItem } = useLocalStorage();
  const {
    vertex_nodes,
    doc_current_page,
    reload_doc,
    selectedRecord,
    openAddFolderModalSt,
    customerDetails,
  } = useAppSelector((state) => state.CustomerDetailsReducer);
  const { page_loader } = useAppSelector((state) => state.CommonReducer);
  const {
    documentNodes,
    designNodes,
    testDataNodes,
    breadcrumbNodes,
    customer_active_node,
    openDeleteModal,
    isFolder,
    openRenameFolderModal,
  } = useAppSelector((state) => state.FolderTreeReducer);
  if (getItem("currentCustomer"))
    customerData =
      !isIndividual && JSON.parse(getItem("currentCustomer") || "");

  const title = isFolder
    ? CONFIRM_MODAL_MESSAGES.DELETE_FOLDER.title
    : CONFIRM_MODAL_MESSAGES.DELETE_FILE.title;
  const description = isFolder
    ? CONFIRM_MODAL_MESSAGES.DELETE_FOLDER.description
    : CONFIRM_MODAL_MESSAGES.DELETE_FILE.description;

  const _initialiseData = (nodes: INodes) => {
    if (!nodes?.id) return;
    if (isIndividual) {
      dispatch(setCustomerActiveNode(nodes));
      dispatch(setCustomerSpecificNodes([nodes]));
      dispatch(
        setBreadCrumb([
          {
            title: nodes.name,
          },
        ])
      );
    }
    !isIndividual &&
      dispatch(
        GET(
          "customerSpecific/rootNode/detail",
          `${ApiUrl.customerSpecificNodes}${nodes?.id}/`
        )()
      );
    dispatch(
      GET(
        "customerSpecific/files",
        `${ApiUrl.customerSpecificNodes}${nodes?.id}/documents/?page_size=10&page=1`
      )()
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setBreadCrumbNodes([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIndividual) {
      const pathname = location?.pathname;
      if (pathname.includes(PATHNAMES.documentation)) {
        _initialiseData(documentNodes);
      } else if (pathname.includes(PATHNAMES.design)) {
        _initialiseData(designNodes);
      } else {
        _initialiseData(testDataNodes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    designNodes,
    documentNodes,
    isIndividual,
    location?.pathname,
    testDataNodes,
  ]);

  useEffect(() => {
    if (
      (!customerData?.nodes?.products?.length &&
        !customerData?.nodes?.platforms?.length) ||
      isIndividual
    )
      return;
    dispatch(setCustomerActiveNode(vertex_nodes[0]));
    if (vertex_nodes?.length) {
      _initialiseData(vertex_nodes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vertex_nodes[0]?.id]);

  const _fetchVertexNodes = () => {
    dispatch(updatePageLoader(true));
    dispatch(
      GET(
        "customerSpecific/nodes",
        `${ApiUrl.customerSpecificNodes}${id}/vertex-nodes/`
      )()
    ).then(() => {
      dispatch(updatePageLoader(false));
    });
  };

  useEffect(() => {
    !isIndividual && _fetchVertexNodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndividual, customerDetails?.nodes]);

  useEffect(() => {
    if (breadcrumbNodes?.length) {
      const breadcrumb = breadcrumbNodes.map(({ name }: { name: string }) => ({
        title: name,
      }));
      dispatch(setBreadCrumb([...breadcrumb]));
    }
  }, [breadcrumbNodes, dispatch]);

  useEffect(() => {
    customer_active_node?.id &&
      reload_doc &&
      dispatch(
        GET(
          "customerSpecific/files",
          `${ApiUrl.customerSpecificNodes}${customer_active_node?.id}/documents/?page_size=10&page=${doc_current_page}`
        )()
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_doc]);

  if (page_loader) return <LoaderLineComponent />;

  const confirmAction = () => {
    if (!isFolder) {
      _handleFileDelete();
      return;
    }
    dispatch(setConfirmFolderDelete(true));
  };

  const _handleFileDelete = () => {
    dispatch(
      DELETE("file/delete", `${ApiUrl.deleteFile}${selectedRecord?.id}/`)()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        dispatch(setOpenDeleteFolderModalSt({ open: false, isFolder: true }));
        dispatch(reloadCustomerDocTable(true));
        triggerToastMessage(
          "File deleted",
          undefined,
          `You deleted ${selectedRecord?.name} file.`
        );
      }
    });
  };

  return (
    <CustomerSpecificWrapper>
      {isIndividual && !page_loader && (
        <CustomerSpecificTreeWrapper isIndividual={isIndividual} />
      )}
      {(customerData?.nodes?.products?.length ||
        customerData?.nodes?.platforms?.length) &&
      !isIndividual ? (
        <CustomerSpecificTreeWrapper isIndividual={isIndividual} />
      ) : (
        !page_loader &&
        !isIndividual && (
          <EmptyData
            customMessage={CUSTOMER_SPECIFIC_EMPTY_MSG.NO_ASSIGNED_PROD}
            type="customer specific detail"
            hideMessage={true}
          />
        )
      )}
      <UploadDocument activeNode={customer_active_node?.id} />

      <AddFolder
        open={openAddFolderModalSt}
        setModalOpenSt={() => dispatch(setOpenAddFolderModal(false))}
        isCustomerSpecific={true}
      />

      <ConfirmationModal
        open={openDeleteModal}
        okText="Yes, Delete"
        title={title}
        subText={description}
        customClass="error-btn"
        onCancel={() =>
          dispatch(setOpenDeleteFolderModalSt({ open: false, isFolder: true }))
        }
        onOk={confirmAction}
      />
      <RenameNode
        open={openRenameFolderModal}
        type={isFolder ? "Folder" : "File"}
      />
    </CustomerSpecificWrapper>
  );
};

export default CustomerSpecific;
