import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserReducer } from "../../interface/User";
import { GET, POST } from "../../service/api";

const initialState: IUserReducer = {
  userTableParam: {
    page: 1,
    ordering: "",
  },
  users_list: [],
  user_table_loader: true,
  total_pages: 0,
  total_data: 0,
  search_user_param: "",
  reload_table: false,
  open_user_modal: false,
  roles: [],
};

const UserSlice = createSlice({
  name: "user_reducer",
  initialState,
  reducers: {
    setSearchParam: (state, action: PayloadAction<string>) => {
      state.search_user_param = action.payload;
    },
    updateUserTableParam: (
      state,
      action: PayloadAction<{ page: number; ordering: string }>
    ) => {
      state.userTableParam = action.payload;
    },
    setUserTableLoader: (state, action: PayloadAction<boolean>) => {
      state.user_table_loader = action.payload;
    },
    setOpenAddUserModal: (state, action: PayloadAction<boolean>) => {
      state.open_user_modal = action.payload;
    },
    resetUserReducer: () => initialState,
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET("users/list", "").fulfilled, (state, action) => {
      const { results, total_count, total_pages } = action.payload.data;
      state.users_list = results?.length ? results : [];
      state.total_pages = total_pages;
      state.total_data = total_count;
      state.user_table_loader = false;
    });
    builder.addCase(GET("users/list", "").rejected, (state, action) => {
      state.users_list = [];
      state.total_pages = 0;
      state.total_data = 0;
      state.user_table_loader = false;
    });
    builder.addCase(GET("users/list", "").pending, (state, action) => {
      state.user_table_loader = true;
      state.reload_table = false;
    });
    builder.addCase(POST("users/add", "").fulfilled, (state, action) => {
      state.user_table_loader = true;
      state.reload_table = false;
    });

    builder.addCase(GET("users/roles", "").fulfilled, (state, action) => {
      state.roles = action?.payload?.data?.results;
      state.reload_table = false;
    });
  },
});

export default UserSlice.reducer;

export const {
  setSearchParam,
  updateUserTableParam,
  setUserTableLoader,
  setOpenAddUserModal,
  resetUserReducer,
  reloadTable,
} = UserSlice.actions;
