import { configureStore } from "@reduxjs/toolkit";
import CommonReducer from "./features/Common.slice";
import AuthReducer from "./features/Auth.slice";
import SignupReducer from "./features/Signup.slice";
import CustomerReducer from "./features/Customer.slice";
import CustomerDetailsReducer from "./features/CustomerDetails.slice";
import ProductReducer from "./features/Product.slice";
import UserReducer from "./features/User.slice";
import ProductDetailsReducer from "./features/ProductDetails.slice";
import FolderTreeReducer from "./features/FolderTree";
import UploadReducer from "./features/UploadDocument.slice";
import UserDetailReducer from "./features/UserDetail.slice";
import TicketsReducer from "./features/Tickets.slice";
import CustomerInfoReducer from "./features/CustomerInfo.slice";
import BlockedIPsReducer from "./features/BlockedIPs.slice";
import NotificationReducer from "./features/Notification.slice";
import RecentlyDeletedItemsReducer from "./features/RecentlyDeleted.slice";
import DashboardSlice from "./features/Dashboard.slice";

const store = configureStore({
  reducer: {
    CommonReducer,
    AuthReducer,
    SignupReducer,
    CustomerReducer,
    ProductReducer,
    UserReducer,
    CustomerDetailsReducer,
    ProductDetailsReducer,
    FolderTreeReducer,
    UploadReducer,
    UserDetailReducer,
    TicketsReducer,
    CustomerInfoReducer,
    BlockedIPsReducer,
    NotificationReducer,
    RecentlyDeletedItemsReducer,
    DashboardSlice,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
