import React, { useState } from "react";
import AddModal from "../../../components/AddModal/AddModal";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { POST } from "../../../service/api";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { STATUS } from "../../../utils/constants/common";
import {
  fetchNodeDetails,
  setDocumentsLoader,
} from "../../../store/features/FolderTree";
import { RESPONSE_MESSAGES } from "../../../utils/constants/messages";
import { triggerToastMessage } from "../../../utils/utils";

const AddFolder = ({
  open,
  setModalOpenSt,
  isCustomerSpecific = false,
}: {
  open: boolean;
  setModalOpenSt: Function;
  isCustomerSpecific?: boolean;
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const { active_node, nodes, customer_active_node } = useAppSelector(
    (state) => state.FolderTreeReducer
  );

  const addFolder = (value: any) => {
    const url = isCustomerSpecific
      ? ApiUrl.customerSpecificNodes
      : ApiUrl.productList;

    const payload = {
      ...value,
      parent: isCustomerSpecific ? customer_active_node?.id : active_node?.id,
    };
    if (!isCustomerSpecific) payload["type"] = nodes?.type;
    dispatch(setDocumentsLoader(true));
    dispatch(POST("product/details/add", url, { ...payload })()).then(
      (res: any) => {
        if (res?.payload?.status === STATUS.SUCCESS) {
          dispatch(fetchNodeDetails(true));
          const { name } = res?.payload?.data;
          triggerToastMessage(
            RESPONSE_MESSAGES.ADD_FOLDER.message,
            STATUS.SUCCESS,
            `${RESPONSE_MESSAGES.ADD_FOLDER.description}'${name}'`
          );
          setIsSuccess(true);
        } else setIsSuccess(false);
        dispatch(setDocumentsLoader(false));
      }
    );
  };

  const reset = () => {
    setModalOpenSt(false);
    setIsSuccess(false);
  };

  return (
    <AddModal
      title="Add New Folder"
      placeholder="Enter folder name"
      add={addFolder}
      reset={reset}
      open={open}
      isSuccess={isSuccess}
      maxlength={50}
    />
  );
};

export default AddFolder;
