import styled from "styled-components";

export const ActivityLogWrapper = styled.section<{ height: string }>`
  padding: 2.8rem 2.8rem 2.2rem 2.8rem;
  height: ${(props: any) => props.height ?? `30rem`};
  overflow: scroll;
  width: 100%;
`;

export const ActivityWrapper = styled.div<{ height: string }>`
  border: 1px solid var(--PAGE-BORDER);
  height: ${(props: any) => props.height ?? `30rem`};
  border-radius: 1rem;
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .backdrop {
    background-color: #c0cfdc3d;
  }
`;
