import { Button } from "antd";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setOpenUploadModal } from "../../store/features/UploadDocument.slice";
import { isInactiveCustomer } from "../../utils/utils";

const UploadButton = ({ addFolder }: { addFolder?: Function }) => {
  const dispatch = useAppDispatch();
  const isInactiveUser = isInactiveCustomer();

  const openUploadModal = () => {
    dispatch(setOpenUploadModal(true));
  };

  return (
    <div className="upload-btn">
      {addFolder && (
        <Button type="primary" onClick={() => addFolder()}>
          Add New Folder
        </Button>
      )}

      <Button
        onClick={openUploadModal}
        className="upload"
        disabled={isInactiveUser}
      >
        Upload File
      </Button>
    </div>
  );
};

export default UploadButton;
