import styled from "styled-components";

export const DetailsContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0.5rem 3.5rem 0.5rem;
  .main-title {
    font-weight: 400;
    font-size: 2.4rem;
    color: var(--PRIMARY);
  }
  .subtitle {
    font-size: 1.8rem;
    color: var(--SECONDARY);
  }
  .btn-outline {
    font-size: 1.6rem;
    height: 4.6rem;
    font-weight: bold;
    color: var(--PRIMARY);
    border: 2px solid var(--PRIMARY);
    background-color: var(--WHITE);
  }
`;

export const UserInfoWarning = styled.div`
  background-color: #142d501a;
  border-radius: 1rem;
  padding: 1.2rem;
  display: flex;
  margin: 2rem 0;
  align-items: center;
  svg {
    width: 2rem;
    margin: 0 1.3rem 0 0;
    height: 2rem;
  }
`;
