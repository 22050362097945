import { Button, Progress, Tooltip } from "antd";
import { UPLOAD_STATUSES } from "../../../utils/constants/common";
import { FileIcon, GreenCheck, CloseBtn } from "../../Icons/UploadIcons";
import {
  FlexBox,
  UploadStatusWrapper,
  UploadWrapper,
} from "../UploadDocumentStyled";

export const UploadStatusCard = (props: {
  status: string;
  progressPercentage: { percentage: number; progress: string };
  reset: Function;
  filename: string;
  data: { errorMsg: string; isDuplicateFolder: boolean; action: Function };
}) => {
  const {
    status,
    progressPercentage: { percentage, progress },
    reset,
    filename,
    data: { errorMsg, isDuplicateFolder, action },
  } = props;
  return (
    <UploadWrapper>
      <UploadStatusWrapper color={status}>
        <FileIcon />
        <div className="filename-container">
          <div className="filename">{filename}</div>
          {status === UPLOAD_STATUSES.inProgress && (
            <div className="progress">
              <div className="progress-indicator">
                <Progress
                  percent={percentage}
                  showInfo={false}
                  strokeColor="#ffbf00"
                />
              </div>
              <div className="progress-text">{progress}</div>
            </div>
          )}
          {status === UPLOAD_STATUSES.success && (
            <FlexBox>
              <GreenCheck />
              <span className="text-green">FILE UPLOADED SUCCESSFULLY</span>
            </FlexBox>
          )}
          {status === UPLOAD_STATUSES.error && (
            <FlexBox>
              <span className="text-red text-uppercase">{errorMsg}</span>
            </FlexBox>
          )}
        </div>
        <Tooltip
          placement="top"
          title={status === UPLOAD_STATUSES.inProgress ? "" : "Cancel"}
        >
          <div
            onClick={() => reset()}
            className={
              status === UPLOAD_STATUSES.inProgress
                ? "btn-disabled"
                : "close-btn"
            }
          >
            <CloseBtn />
          </div>
        </Tooltip>
      </UploadStatusWrapper>
      {isDuplicateFolder && (
        <div className="btn-replace">
          <Button
            disabled={status === UPLOAD_STATUSES.inProgress}
            type="text"
            onClick={() => action(false)}
          >
            Keep Both
          </Button>
          <Button
            disabled={status === UPLOAD_STATUSES.inProgress}
            type="primary"
            onClick={() => action(true)}
          >
            Replace
          </Button>
        </div>
      )}
    </UploadWrapper>
  );
};
