import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { POST_FORMDATA } from "../../service/api";

const initialState: any = {
  folder: [],
  openUploadModal: false,
  upload_percentage: 0,
};
const UploadDocumentSlice = createSlice({
  name: "upload_document_reducer",
  initialState,
  reducers: {
    setOpenUploadModal: (state, action: PayloadAction<boolean>) => {
      state.openUploadModal = action.payload;
    },
    setUploadPercentage: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload;
    },
    resetFileUploadReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      POST_FORMDATA("document/upload", "").fulfilled,
      (state, action) => {
        const { results, total_count, total_pages } = action.payload.data;
        state.customers_list = results;
        state.total_pages = total_pages;
        state.total_data = total_count;
        state.customer_table_loader = false;
      }
    );
  },
});
export default UploadDocumentSlice.reducer;
export const {
  setOpenUploadModal,
  resetFileUploadReducer,
  setUploadPercentage,
} = UploadDocumentSlice.actions;
