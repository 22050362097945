import styled from "styled-components";

export const Spacer = styled.div`
  padding: 1rem;
`;

export const ActivityWrapper = styled.div<{ role: string }>`
  display: flex;
  flex-direction: column;
  .title {
    font-size: 1.4rem;
    color: #142d50;
  }
  .subtext {
    display: flex;
    align-items: center;
    .name {
      font-size: 1rem;
      font-weight: 500;
      color: #142d50;
    }
    .dot {
      height: 0.3rem;
      width: 0.25rem;
      background-color: #142d5080;
      border-radius: 50%;
      margin: 0 0.5rem;
    }
    .role {
      font-size: 1rem;
      font-weight: 700;
      color: ${(props) =>
        `var(--TEXT-${props?.role
          ?.toUpperCase()
          .replace(" ", "")}) !important`};
    }
  }
`;

export const UserActivity = ({
  message,
  user,
  role,
}: {
  message: string;
  user: string;
  role: string;
}) => {
  return (
    <>
      <ActivityWrapper role={role}>
        <div className="title">{message}</div>
        <div className="subtext">
          <div className="name">{user}</div>
          <div className="dot"></div>
          <div className="role">{role}</div>
        </div>
      </ActivityWrapper>
    </>
  );
};
