import { MenuProps, Tooltip } from "antd";
import React from "react";
import ActionMenu from "../../ActionMenu/ActionMenu";
import {
  isAdmin,
  isCurrentUser,
  isIndividualUser,
  isSuperAdminUser,
} from "../../../utils/utils";
import { IFile } from "../../../interface/ProductDetails";
import { MORE_ACTIONS } from "../../../utils/constants/common";
import {
  setOpenDeleteFolderModalSt,
  setOpenRenameFolderModal,
} from "../../../store/features/FolderTree";
import { useAppDispatch } from "../../../hooks/reduxHooks";

const FileActions = ({
  data,
  items,
}: {
  data: IFile;
  items: MenuProps["items"];
}) => {
  const dispatch = useAppDispatch();
  const hasPermission =
    isSuperAdminUser() ||
    ((isAdmin() || isIndividualUser()) &&
      isCurrentUser(data?.created_by || ""));

  const handleMenuClick = (item: { key: string }) => {
    switch (item.key) {
      case MORE_ACTIONS.DELETE:
        dispatch(setOpenDeleteFolderModalSt({ open: true, isFolder: false }));
        break;
      case MORE_ACTIONS.RENAME:
        dispatch(setOpenRenameFolderModal({ open: true, isFolder: false }));
        break;
      default:
        break;
    }
  };
  return (
    <>
      {hasPermission && (
        <Tooltip placement="top" title="More Actions">
          <div className="actions">
            <ActionMenu
              data={data}
              items={items}
              handleAction={(item: any) => handleMenuClick(item)}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default FileActions;
