import { Modal } from "antd";
import styled from "styled-components";
import { UPLOAD_STATUSES } from "../../utils/constants/common";

export const UploadModalWrapper = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 2.7rem;
  }
  .ant-upload-wrapper {
    width: 100%;
  }
  .ant-upload-wrapper .ant-upload-drag {
    background: transparent;
    border: 2px dashed var(--PRIMARY);
    border-radius: 2rem;
    padding: 3rem;
  }
  .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 33rem;
    .drag-area-content {
      margin: 5rem 0;
      .main-text {
        font-size: 1.6rem;
        color: #000;
      }
      .sub-text {
        font-size: 1.2rem;
        color: #142d50bf;
      }
      .choose-file {
        font-size: 1.2rem;
        padding: 1rem;
        margin: 0.5rem;
      }
    }
  }
  .ant-modal .ant-modal-content {
    padding: 32px;
  }
`;

export const UploadModalTitleWrapper = styled.div`
  display: flex;
`;

export const SelectFolder = styled.div`
  margin: 0 1.1rem;
  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--PRIMARY) !important;
    height: 4.3rem;
    margin: -0.4rem 0 0 0;
  }
  .ant-select .ant-select-arrow {
    color: var(--PRIMARY);
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .option-label {
    margin-left: 0.7rem;
    padding: 0.5rem;
  }
  svg {
    min-width: 2rem;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .btn-replace {
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
  }
  button {
    padding: 2rem 3.5rem;
    &:last-child {
      margin: 0 2rem 0 1rem;
    }
  }
`;

export const UploadStatusWrapper = styled.div`
  padding: 1rem;
  border: ${(props) =>
    props.color === UPLOAD_STATUSES.error
      ? `1px solid #e4002b`
      : `1px solid #142d5080`};
  border-radius: 0.8rem;
  background-color: ${(props) =>
    props.color === UPLOAD_STATUSES.error ? "#FFF3F5" : "transparent"};
  width: 37.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .filename {
    text-align: left;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .filename-container {
    width: 26rem;
  }
  .progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .progress-indicator {
      width: 74%;
    }
    .progress-text {
      width: 25%;
      font-size: 1rem;
    }
  }
  .close-btn {
    cursor: pointer;
    margin-left: 1.5rem;
  }
  .btn-disabled {
    margin-left: 1.5rem;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }
  .text-green {
    color: #008675;
    font-size: 1rem;
    margin: 0.3rem 0 0 0.3rem;
  }
  .text-red {
    font-size: 1rem;
    color: #e4002b;
    margin: 0.3rem 0 0 0;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
