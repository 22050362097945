import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INodes, IProductDetailsReducer } from "../../interface/ProductDetails";
import { IProductTable } from "../../interface/Product";
import { POST } from "../../service/api";

const selectedProductInitialSt = {
  id: "",
  name: "",
  sub_nodes: [],
  documents: [],
  is_root_node: false,
  expanded: false,
  loading: false,
  type: "",
  path: [],
  hasFiles: false,
  hasPermission: false,
};

const initialState: IProductDetailsReducer = {
  selected_product_details: selectedProductInitialSt,
  current_product: {
    id: "",
    name: "",
    added_date: "",
    status: "",
    customers: [],
    created_by: "",
    type: "",
  },
  active_node: selectedProductInitialSt,
};
const ProductDetailsSlice = createSlice({
  name: "product_details_reducer",
  initialState,
  reducers: {
    setCurrentProduct: (state, action: PayloadAction<IProductTable>) => {
      state.current_product = action.payload;
    },
    setProductDetails: (state, action: PayloadAction<INodes>) => {
      state.selected_product_details = action.payload;
    },
    resetProductDetailsReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(POST("products/details", "").fulfilled, (state, action) => {
      state.selected_product_details = action.payload.data;
      state.active_node = action.payload.data;
    });
  },
});
export default ProductDetailsSlice.reducer;
export const {
  setProductDetails,
  setCurrentProduct,
  resetProductDetailsReducer,
} = ProductDetailsSlice.actions;
