import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { GET, POST } from "../../service/api";
import { IProductReducer, IProductTable } from "../../interface/Product";

const initialItem: IProductTable = {
  id: "",
  name: "",
  added_date: "",
  status: "",
  customers: [],
  created_by: "",
  type: "",
};

const initialState: IProductReducer = {
  productTableParam: {
    page: 1,
    ordering: "",
  },
  product_list: [],
  customer_product_list: [],
  all_products_list: [],
  product_table_loader: true,
  total_pages: 0,
  total_data: 0,
  search_param: "",
  reload_table: false,
  openModal: false,
  isApiErr: false,
  openActionModal: false,
  selected_action_item: initialItem,
};
const ProductSlice = createSlice({
  name: "product_reducer",
  initialState,
  reducers: {
    setSearchParam: (state, action: PayloadAction<string>) => {
      state.search_param = action.payload;
    },
    updateProductTableParam: (
      state,
      action: PayloadAction<{ page: number; ordering: string }>
    ) => {
      state.productTableParam = action.payload;
    },
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
    setProductTableLoader: (state, action: PayloadAction<boolean>) => {
      state.product_table_loader = action.payload;
    },
    setAddProductModalOpenSt: (state, action: PayloadAction<boolean>) => {
      state.openModal = action.payload;
    },
    setProductActionModalSt: (state, action: PayloadAction<boolean>) => {
      state.openActionModal = action.payload;
    },
    setSelectedActionItem: (state, action: PayloadAction<IProductTable>) => {
      state.selected_action_item = action.payload;
    },
    resetProductReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("all-products/list", "").fulfilled, (state, action) => {
      const results = action.payload?.data;
      state.all_products_list = results;
    });
    builder.addCase(POST("products/add", "").fulfilled, (state, action) => {
      state.product_table_loader = true;
      state.reload_table = false;
    });
    builder.addCase(POST("customer-products/add", "").fulfilled, (state) => {
      state.product_table_loader = true;
      state.reload_table = false;
    });
    builder.addMatcher(
      isAnyOf(
        GET("products/list", "").fulfilled,
        GET("customer-products/list", "").fulfilled
      ),
      (state, action) => {
        const { results, total_count, total_pages } = action.payload.data;
        if (action.type === "products/list/fulfilled")
          state.product_list = results;
        if (action.type === "customer-products/list/fulfilled")
          state.customer_product_list = results;
        state.total_pages = total_pages;
        state.total_data = total_count;
        state.product_table_loader = false;
        state.isApiErr = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        GET("products/list", "").pending,
        GET("customer-products/list", "").pending
      ),
      (state, action) => {
        if (action.type === "customer-products/list/pending")
          state.customer_product_list = [];
        state.product_table_loader = true;
        state.reload_table = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        GET("products/list", "").rejected,
        GET("customer-products/list", "").rejected
      ),
      (state, action) => {
        if (action.type === "products/list/rejected") state.product_list = [];
        if (action.type === "customer-products/list/rejected")
          state.customer_product_list = [];
        state.total_data = 0;
        state.total_pages = 0;
        state.product_table_loader = false;
        state.isApiErr = true;
      }
    );
  },
});
export default ProductSlice.reducer;
export const {
  setSearchParam,
  updateProductTableParam,
  setProductTableLoader,
  reloadTable,
  setAddProductModalOpenSt,
  setProductActionModalSt,
  setSelectedActionItem,
  resetProductReducer,
} = ProductSlice.actions;
