import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { INodes } from "../../../interface/ProductDetails";
import { DELETE, GET, PATCH } from "../../../service/api";
import {
  fetchNodeDetails,
  setActiveNode,
  setBreadCrumbNodes,
  setConfirmFolderDelete,
  setConfirmRenameFolder,
  setCustomerActiveNode,
  setNodes,
  setOpenDeleteFolderModalSt,
  setOpenRenameFolderModal,
  updateDocPage,
} from "../../../store/features/FolderTree";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import {
  CUSTOMER_SPECIFIC_FOLDERS,
  MORE_ACTIONS,
  STATUS,
} from "../../../utils/constants/common";
import {
  capitalizeFirstLetter,
  isCurrentUser,
  triggerToastMessage,
} from "../../../utils/utils";
import { RightArrow, FolderIcon } from "../../Icons/Icons";
import { TreeLoader } from "../../Loader/Loader";
import ActionMenu from "../../ActionMenu/ActionMenu";
import {
  setCustomerSpecificNodes,
  updateCustomerDocPage,
} from "../../../store/features/CustomerDetails.slice";
import { Tooltip } from "antd";
import { FILE_STARTED_DOWNLOAD } from "../../../utils/constants/messages";

export const TreeNode = ({
  nodeItem,
  id,
  name,
  isExpanded,
  isLoading = false,
  children = [],
  type = "",
  hasPermission = false,
}: {
  nodeItem: INodes;
  id: string;
  name: string;
  isExpanded: boolean;
  isLoading?: boolean;
  children?: INodes[];
  type?: string;
  hasPermission?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    active_node,
    nodes,
    fetchNode,
    customer_active_node,
    confirmFolderDelete,
    confirmRenameFolder,
    renamedFolder,
    breadcrumbNodes,
  } = useAppSelector((state) => state.FolderTreeReducer);
  const { selectedRecord, vertex_nodes } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const userHasPermission = isCurrentUser(nodeItem?.created_by || "");

  const hasSubNodes =
    nodeItem?.hasFiles ||
    (nodeItem?.sub_nodes && nodeItem?.sub_nodes?.length > 0) ||
    (nodeItem?.no_of_documents && nodeItem?.no_of_documents > 0) ||
    (nodeItem?.no_of_sub_nodes && nodeItem?.no_of_sub_nodes > 0);

  useEffect(() => {
    //to auto expand and show newly added folders in tree
    if (
      fetchNode &&
      (nodeItem?.id === active_node?.id ||
        nodeItem?.id === customer_active_node?.id)
    ) {
      expandOrCollapseNode(!type ? active_node : customer_active_node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNode, active_node?.id, customer_active_node?.id]);

  useEffect(() => {
    confirmRenameFolder &&
      renamedFolder?.node &&
      nodeItem?.id === selectedRecord?.id &&
      _renameFolder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmRenameFolder, selectedRecord?.id, renamedFolder?.node]);

  useEffect(() => {
    confirmFolderDelete &&
      nodeItem?.id === selectedRecord?.id &&
      _deleteFolder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmFolderDelete, selectedRecord?.id]);

  const expandOrCollapseNode = (treeNode: INodes) => {
    _createSelectedNodeParams(treeNode);
  };

  const selectNode = (selectedNode: INodes) => {
    if (
      active_node?.id === selectedNode?.id ||
      customer_active_node?.id === selectedNode?.id
    ) {
      return;
    }
    _setActiveNodes(selectedNode);
    //If the node is clicked for a first time or a new folder is added, fetch the results from API
    if (
      !Object.hasOwn(selectedNode, "sub_nodes") ||
      fetchNode ||
      (!selectedNode?.is_root_node && !Object.hasOwn(selectedNode, "path")) ||
      (selectedNode?.sub_nodes?.length === 0 &&
        selectedNode?.no_of_sub_nodes &&
        selectedNode?.no_of_sub_nodes > 0)
    ) {
      _fetchChildNodes(selectedNode, true);
    }
    _fetchDocs(type, selectedNode);
    _createBreadcrumbOptions(selectedNode);
  };

  const _fetchDocs = (type: string, selectedNode: INodes) => {
    // fetching pragma node documents
    if (!type) {
      dispatch(
        GET(
          "nodes/files",
          `${ApiUrl.productList}${selectedNode?.id}/documents/?page_size=10&page=1`
        )() // fetching the call again on page change
      );
      dispatch(updateDocPage(1));
      return;
    }
    dispatch(
      GET(
        "customerSpecific/files",
        `${ApiUrl.customerSpecificNodes}${selectedNode?.id}/documents/?page_size=10&page=1`
      )()
    );
    dispatch(updateCustomerDocPage(1));
  };

  const _createSelectedNodeParams = (treeNode: INodes) => {
    treeNode = {
      ...treeNode,
      expanded:
        (treeNode?.sub_nodes || [])?.length > 0 ||
        (treeNode?.no_of_sub_nodes && treeNode?.no_of_sub_nodes > 0)
          ? !treeNode.expanded
          : treeNode?.expanded,
    };
    // if clikced on the root node --> set the nodes as the selected node

    if (treeNode?.is_root_node && !type) {
      !fetchNode
        ? _updateNodes(treeNode, treeNode?.sub_nodes || [])
        : _fetchChildNodes(treeNode);
      return;
    }
    //If the node is clicked for a first time or a new folder is added, fetch the results from API
    if (
      !Object.hasOwn(treeNode, "sub_nodes") ||
      fetchNode ||
      (treeNode?.sub_nodes?.length === 0 &&
        treeNode?.no_of_sub_nodes &&
        treeNode?.no_of_sub_nodes > 0)
    ) {
      _fetchChildNodes(treeNode);
      return;
    }
    //If node is clicked before and has sub nodes, update the expand icon status
    const updatedTree = _updateTreeData(
      !type ? nodes?.sub_nodes || [] : vertex_nodes || [],
      treeNode,
      treeNode?.sub_nodes || []
    );
    _updateNodes(!type ? nodes : [...updatedTree], [...updatedTree]);
  };

  const _setActiveNodes = (selectedNode: INodes) => {
    !type
      ? dispatch(setActiveNode(selectedNode))
      : dispatch(
          setCustomerActiveNode({ ...selectedNode, path: selectedNode?.path })
        );
  };

  const _fetchChildNodes = (treeNode: INodes, nodeSelected = false) => {
    fetchNode && dispatch(fetchNodeDetails(false));
    treeNode = { ...treeNode, loading: !nodeSelected };
    const actionType = !type ? "node/details" : "customerSpecific/node/details";
    const url = !type ? ApiUrl.productList : ApiUrl.customerSpecificNodes;
    dispatch(GET(actionType, `${url}${treeNode.id}/`)()).then((res) => {
      treeNode = { ...treeNode, loading: false };
      const {
        payload: { data, status },
      } = res;
      if (status === STATUS.SUCCESS) {
        treeNode = {
          ...treeNode,
          path: data?.path,
          sub_nodes: [...data?.sub_nodes],
          expanded: nodeSelected
            ? treeNode?.expanded
            : data?.sub_nodes?.length > 0,
          hasFiles: fetchNode || treeNode?.hasFiles,
        };
        (nodeSelected || fetchNode) && _setActiveNodes(treeNode);
        _checkUserActionPermission(treeNode); //checking for file action permission
        if (treeNode?.is_root_node && !type) {
          _updateNodes(nodes, data?.sub_nodes);
          return;
        }
        const updatedTree = _updateTreeData(
          !type ? nodes?.sub_nodes || [] : vertex_nodes || [],
          treeNode,
          data?.sub_nodes
        );
        _updateNodes(!type ? nodes : [...updatedTree], [...updatedTree]);
        if (nodeSelected) _createBreadcrumbOptions(treeNode);
      }
    });
  };

  const _checkUserActionPermission = (treeNode: INodes) => {
    if (
      (userHasPermission || treeNode?.hasPermission) &&
      treeNode?.sub_nodes?.length
    ) {
      let subNodes = [...treeNode?.sub_nodes];
      subNodes = subNodes?.map((childNodes: INodes) => ({
        ...childNodes,
        hasPermission: true,
      }));
      treeNode = { ...treeNode, sub_nodes: [...subNodes] };
    }
  };

  const _updateNodes = (
    nodeData: INodes | INodes[],
    subNodes: INodes[] = []
  ) => {
    if (!type) {
      dispatch(
        setNodes({
          ...(nodeData as INodes),
          sub_nodes: [...subNodes],
        })
      );
      return;
    }
    dispatch(setCustomerSpecificNodes([...(nodeData as INodes[])]));
  };

  const _updateTreeData = (
    productNodes: INodes[],
    treeNode: INodes,
    childData: INodes[]
  ): INodes[] =>
    productNodes.map((node: INodes) => {
      if (node.id === treeNode?.id) {
        return {
          ...treeNode,
          sub_nodes: treeNode?.sub_nodes,
        };
      }
      if (node?.sub_nodes) {
        return {
          ...node,
          sub_nodes: _updateTreeData(node?.sub_nodes, treeNode, childData),
        };
      }
      return node;
    });

  const _createBreadcrumbOptions = (selectedNode: INodes) => {
    if (selectedNode?.path && !Array.isArray(selectedNode?.path)) {
      const pathArr: string[] = Object.values(selectedNode?.path);
      const parentIdArr = Object.keys(selectedNode?.path);
      const breadCrumbNodes = parentIdArr.map((id: string, index) => ({
        id,
        name: pathArr[index],
      }));
      breadCrumbNodes.push({ id: selectedNode?.id, name: selectedNode?.name });
      dispatch(setBreadCrumbNodes([...breadCrumbNodes]));
    }
    if (selectedNode?.is_root_node)
      dispatch(
        setBreadCrumbNodes([{ id: selectedNode?.id, name: selectedNode?.name }])
      );
  };

  const handleMenuClick = (item: { value: string }) => {
    switch (item.value) {
      case MORE_ACTIONS.DELETE:
        dispatch(setOpenDeleteFolderModalSt({ open: true, isFolder: true }));
        break;
      case MORE_ACTIONS.RENAME:
        dispatch(setOpenRenameFolderModal({ open: true, isFolder: true }));
        break;
      case MORE_ACTIONS.ZIP_DOWNLOAD:
      case MORE_ACTIONS.TAR_DOWNLOAD:
      case MORE_ACTIONS.TAR_GZ_DOWNLOAD:
        dispatch(
          GET(
            "nodes/download",
            `${ApiUrl.downloadFolder}${selectedRecord?.id}/?archive_format=${item?.value}`
          )()
        ).then((res: any) => {
          if (res?.payload?.status === STATUS.SUCCESS) {
            triggerToastMessage(
              FILE_STARTED_DOWNLOAD.message,
              "info",
              FILE_STARTED_DOWNLOAD.description
            );
          }
        });
        break;
      default:
        break;
    }
  };

  const _deleteFolder = () => {
    dispatch(setConfirmFolderDelete(false));
    const url = !type ? ApiUrl.productList : ApiUrl.customerSpecificNodes;
    dispatch(DELETE("nodes/delete", `${url}${selectedRecord?.id}/`)()).then(
      (res: any) => {
        if (res?.payload?.status === STATUS.SUCCESS) {
          const updatedTree = _updateTreeData(
            !type ? nodes?.sub_nodes || [] : vertex_nodes || [],
            { ...selectedRecord, isDeleted: true },
            []
          );
          _updateNodes(!type ? nodes : [...updatedTree], [...updatedTree]);
          (active_node?.id === selectedRecord?.id ||
            customer_active_node?.id === selectedRecord?.id) &&
            _resetNodeSelection();
          triggerToastMessage(
            "Folder deleted",
            undefined,
            `You deleted '${name}'`
          );

          dispatch(setOpenDeleteFolderModalSt({ open: false, isFolder: true }));
        }
      }
    );
  };

  const _renameFolder = () => {
    dispatch(setConfirmRenameFolder(false));
    const url = !type ? ApiUrl.productList : ApiUrl.customerSpecificNodes;
    dispatch(
      PATCH(
        "nodes/rename",
        `${url}${selectedRecord?.id}/${ApiUrl.renameFolder}`,
        {
          ...renamedFolder,
        }
      )()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        if (
          active_node?.id === selectedRecord?.id ||
          customer_active_node?.id === selectedRecord?.id
        ) {
          !type
            ? dispatch(
                setActiveNode({ ...active_node, name: renamedFolder?.new_name })
              )
            : dispatch(
                setCustomerActiveNode({
                  ...customer_active_node,
                  name: renamedFolder?.new_name,
                })
              );
        }
        const updatedTree = _updateTreeData(
          !type ? nodes?.sub_nodes || [] : vertex_nodes || [],
          { ...selectedRecord, name: renamedFolder?.new_name },
          selectedRecord?.sub_nodes || []
        );
        _updateNodes(!type ? nodes : [...updatedTree], [...updatedTree]);
        _renameBreadcrumbs();
        dispatch(setOpenRenameFolderModal({ open: false, isFolder: true }));
        triggerToastMessage(
          "Folder renamed",
          "success",
          `You renamed folder '${name}' to '${renamedFolder?.new_name}'`
        );
      }
    });
  };

  // renaming the breadcrumbs after renaming a folder
  const _renameBreadcrumbs = () => {
    const breadcrumbIndex = breadcrumbNodes.findIndex(
      (breadcrumb: any) => breadcrumb.id === selectedRecord?.id
    );
    if (breadcrumbIndex < 0) return;
    const breadcrumbs = [...breadcrumbNodes];
    breadcrumbs[breadcrumbIndex] = {
      id: selectedRecord?.id,
      name: renamedFolder?.new_name,
    };
    dispatch(setBreadCrumbNodes([...breadcrumbs]));
  };

  const _resetNodeSelection = () => {
    !type ? dispatch(setActiveNode(nodes)) : _resetCustomerNode();
    _fetchDocs(type, !type ? nodes : vertex_nodes[0]);
    _createBreadcrumbOptions(!type ? nodes : vertex_nodes[0]);
  };

  //function to set ative node as the top parent customer speific folder if user is deletting the active node
  const _resetCustomerNode = () => {
    if (selectedRecord?.path && !Array.isArray(selectedRecord?.path)) {
      const values: string[] = Object.values(selectedRecord?.path);
      const rootNodeName = CUSTOMER_SPECIFIC_FOLDERS.filter(
        (name: string) => values[0].toLowerCase() === name
      );
      const rootNodeElem = (vertex_nodes || []).find(
        (elem: INodes) => elem.name.toLowerCase() === rootNodeName[0]
      );
      rootNodeElem && dispatch(setCustomerActiveNode(rootNodeElem));
    }
  };

  const showArrow =
    (nodeItem?.sub_nodes && nodeItem?.sub_nodes?.length > 0) ||
    (nodeItem?.no_of_sub_nodes && nodeItem?.no_of_sub_nodes > 0);

  return (
    <>
      <li
        className={`d-flex tree ${
          (active_node?.id === id || customer_active_node?.id === id) &&
          "active-node"
        }`}
      >
        <Tooltip
          placement="top"
          title={
            showArrow && !nodeItem?.is_root_node
              ? isExpanded
                ? "Collapse"
                : "Expand"
              : ""
          }
        >
          <span
            className={`arrow ${isExpanded && "arrow-down"} ${
              showArrow && "arrow-hover"
            }`}
            onClick={(e) => expandOrCollapseNode(nodeItem)}
          >
            {showArrow ? (
              <>{!isLoading ? <RightArrow /> : <TreeLoader />}</>
            ) : (
              <></>
            )}
          </span>
        </Tooltip>
        <span className="d-flex folder" onClick={() => selectNode(nodeItem)}>
          <FolderIcon />
          <span
            className={`name ${
              (active_node?.id === id || customer_active_node?.id === id) &&
              "active"
            } ${hasSubNodes && "text-bold"}`}
          >
            {capitalizeFirstLetter(name)}
          </span>
          <span>
            <ActionMenu
              data={nodeItem}
              handleAction={(item: any) => handleMenuClick(item)}
              type="download"
            />
          </span>
        </span>
      </li>
    </>
  );
};
