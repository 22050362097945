import { PageHeader } from "../../utils/TableStyle";
import { ActivityLogWrapper, ActivityWrapper } from "./ActivityLogStyled";
import ActivityTimeline, {
  ITimelineObject,
} from "../../components/ActivityTimeline/ActivityTimeline";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { GET } from "../../service/api";
import {
  resetCustomerInfoReducer,
  setActivityListLoader,
  setActivityPage,
} from "../../store/features/CustomerInfo.slice";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { formatTimelineData } from "../../utils/utils";
import {
  STATUS,
  STORAGE_KEYS,
  USER_ACTIVITY_TYPES,
} from "../../utils/constants/common";
import { Empty } from "antd";
import { HeaderWrapper } from "../UserDetail/UserInfoStyled";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Loader from "../../components/Loader/Loader";

const ActivityLog = ({ type }: { type?: string }) => {
  const dispatch = useAppDispatch();
  const { getItem } = useLocalStorage();
  const customer = JSON.parse(`${getItem(STORAGE_KEYS.currCustomer)}`);
  const [userActivityData, setUserActivityData] = useState<ITimelineObject[]>(
    []
  );
  const {
    activity_log,
    activityLogParams,
    reload_list,
    total_pages,
    activity_list_loader,
  } = useAppSelector((state) => state.CustomerInfoReducer);
  const location = useLocation();

  useEffect(() => {
    dispatch(resetCustomerInfoReducer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getActivityLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_list]);

  const getActivityLog = () => {
    dispatch(setActivityListLoader(true));
    const urlPrefix =
      type === USER_ACTIVITY_TYPES.customer
        ? `${ApiUrl.customerList}${customer.id}/`
        : `${ApiUrl.userList}`;
    const url = `${urlPrefix}${ApiUrl.activityLogs}?page_size=${activityLogParams.page_size}&page=${activityLogParams.page}`;

    dispatch(GET("activity-logs/list", url)()).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        formatActivityData(res?.payload?.data?.results);
      }
      dispatch(setActivityListLoader(false));
    });
  };

  const formatActivityData = (activityLog: any) => {
    const dat: ITimelineObject[] = formatTimelineData(activityLog, type) ?? [];
    const prev = userActivityData?.filter((res: any) => !!res.label);
    const data = [...prev, ...dat]?.filter(
      (obj1: any, i, arr) =>
        arr.findIndex((obj2: any) => obj2.id === obj1.id) === i
    );
    setUserActivityData([...data]);
  };

  const loadMore = ({ target }: any) => {
    const reachedEnd =
      Math.abs(
        target.scrollHeight - (target.scrollTop + target.clientHeight)
      ) <= 10;
    if (reachedEnd && activityLogParams.page < total_pages) {
      let page =
        activityLogParams.page < total_pages
          ? activityLogParams.page + 1
          : activityLogParams.page;
      if (page !== activityLogParams.page) {
        dispatch(setActivityPage(page));
      }
    }
  };

  return (
    <>
      <PageHeader>
        {type === USER_ACTIVITY_TYPES.customer && (
          <HeaderWrapper>
            <p className="user-info-header">
              <span className="title">Activity Log</span>
              <span className="sub">Activities under this customer</span>
            </p>
          </HeaderWrapper>
        )}
      </PageHeader>
      <ActivityWrapper
        height={type === USER_ACTIVITY_TYPES.customer ? "33rem" : "46rem"}
      >
        {activity_log.length > 0 && userActivityData[0]?.label && (
          <>
            <ActivityLogWrapper
              className={
                type !== USER_ACTIVITY_TYPES.customer ? "user-log" : ""
              }
              height={type === USER_ACTIVITY_TYPES.customer ? "33rem" : "46rem"}
              onScroll={(ev: any) => loadMore(ev)}
            >
              <ActivityTimeline data={userActivityData} />
            </ActivityLogWrapper>
          </>
        )}
        {activity_log?.length === 0 && (
          // <ContentWrapper className="empty-table">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          // </ContentWrapper>
        )}
        {activity_list_loader && <Loader />}
      </ActivityWrapper>
    </>
  );
};

export default ActivityLog;
