export const VALIDATION_ERRORS = {
  email: "Email is required",
  currentPassword: "Please enter your current password",
  newPassword: "Please enter a valid new password",
  password: "Password is required",
  validEmail: "Please enter a valid email",
  validPassword: "Please enter a valid password",
  name: "Name is required",
  recoveryCode: "Recovery code is required",
  validCode: "Please enter a valid  code",
  selectUser: "Please select a user",
  selectRole: "Please select a role",
  selectAtleastOneProduct: "Please select atleast one product/platform",
};

export const USER_TYPES = {
  admin: "Admin",
  viewer: "Viewer",
  superadmin: "Superadmin",
  superuser: "Customer superuser",
  individual: "Customer individual",
};

export enum REGISTRATION_STEPS {
  MFA = "mfa",
  SIGNUP_SUCCESS = "signup-success",
  CREATE_PASSWORD = "create-password",
}
export enum HTTP_METHODS {
  GET = "get",
  POST = "post",
}

export enum STATUS {
  SUCCESS = "success",
  ERROR = "error",
}

export enum USER_STATUS {
  NV = "NV",
  AV = "AV",
  DL = "DL",
}

export enum USER_ROLES {
  SUPER_ADMIN = "super admin",
  INDIVIDUAL = "individual",
  VIEWER = "viewer",
  ADMIN = "admin",
}

export const USER_ASSIGN_ROLES = ["viewer", "admin"];

export const APP_IDLE_TIME = 30;

export enum SORT_ORDER {
  asc = "ascend",
  desc = "descend",
}

export enum ERROR_CODES {
  UNAUTHORIZED = 401,
  DUPLICATE_FILE = 409,
}

export enum UPLOAD_STATUSES {
  notStarted = "notStarted",
  inProgress = "inProgress",
  success = "success",
  error = "error",
}

export const RESTRICTED_FOLDERS = ["documentation", "designs"];

export const CUSTOMER_SPECIFIC_FOLDERS = [
  "documentation",
  "designs",
  "test data",
];

export const VIEWER_ACCESS_FOLDERS = ["designs", "test data"];

export enum STORAGE_KEYS {
  currCustomer = "currentCustomer",
  user = "user",
}

export const FILE_UPLOAD = {
  MAX_SIZE_MB: 200,
  BLOCKED_FILE_TYPES: [".exe"],
};

export enum PRODUCT_TYPES {
  product = "product",
  platform = "platform",
}

export enum USER_ACTIVITY_TYPES {
  customer = "customer",
  individual = "individual",
}

export enum CUSTOMER_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const FILE_UPLOAD_CATEGORIES = {
  documentation: "Documentation",
  designs: "Designs",
};

export enum SET_PASSWORD_CARD_TITLE {
  resetPassword = "Reset Password",
  createPassword = "Create Password",
}

export enum MORE_ACTIONS {
  FILE = "file",
  DELETE = "delete",
  RENAME = "rename",
  FOLDER = "folder",
  DOWNLOAD = "download",
  ZIP_DOWNLOAD = "zip",
  TAR_DOWNLOAD = "tar",
  TAR_GZ_DOWNLOAD = "tar.gz",
  RESTORE = "restore",
  UNASSIGN = "unassign",
}

export enum CUSTOMER_TYPES {
  PROD = "product",
  PLATFORM = "platform",
  NO_PROD = "no product",
}

export const VERIFY_USER_PATH = {
  ACTIVATE: "/activate/user",
  SIGNUP: "/signup",
  RESET_PASSWORD: "/reset-password",
};

export const VERIFICATION_ROUTES = [
  VERIFY_USER_PATH.ACTIVATE,
  VERIFY_USER_PATH.SIGNUP,
  VERIFY_USER_PATH.RESET_PASSWORD,
];

export enum USER_ACCOUNT_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  INVITED = "invited",
}
export enum TABLE_LIST_TYPE {
  customer = "customers",
  product = "products/platforms",
}

export enum NOTIFICATION_TYPES {
  PAGE_LOAD = "page_load_notifications",
  LOAD_NOTIFICATION = "load_notifications",
  NEW_NOTIFICATION = "new_notification",
  READ_ALL = "read_all_notifications",
}

export enum FILE_EXT {
  PDF = "pdf",
}

export const CUSTOMER_PATHS = [
  "documentation",
  "designs",
  "test_data",
  "customers",
];

export const RESPONSE_TYPE = {
  upload_rejected: "document/upload/rejected",
};

export const ANTD_TABLE_LOCALE = {
  triggerDesc: "Click to sort",
  triggerAsc: "Click to sort",
  cancelSort: "Click to sort",
};

export const multiPartExtensions = [
  "tar.gz",
  "tar.bz2",
  "tar.xz",
  "tar.Z",
  "tar.lz",
  "tar.lzma",
];
