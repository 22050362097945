import { Modal } from "antd";
import { useEffect } from "react";

const ConfirmationModal = ({
  open,
  okText,
  onOk,
  onCancel,
  title,
  subText,
  customClass = "",
}: {
  open: boolean;
  okText: string;
  onOk: Function;
  onCancel: Function;
  title: string;
  subText: string;
  customClass?: string;
}) => {
  useEffect(() => {
    return () => {
      open && onCancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      destroyOnClose
      centered
      width={"635px"}
      className="modal-title"
      title={title}
      open={open}
      okText={okText}
      okButtonProps={{
        size: "large",
        className: customClass,
        type: customClass ? "default" : "primary",
      }}
      cancelButtonProps={{ size: "large", type: "text" }}
      onOk={() => onOk()}
      onCancel={() => onCancel()}
      closable={false}
    >
      <p className="sub-text">{subText}</p>
    </Modal>
  );
};

export default ConfirmationModal;
