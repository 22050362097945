import StatusTag from "../StatusTag/StatusTag";
import styled from "styled-components";
import { IUserDetails } from "../../interface/UserDetail";
import {
  capitalizeFirstLetter,
  isIndividualUser,
  isSuperAdminUser,
} from "../../utils/utils";
import { TABLE_LIST_TYPE } from "../../utils/constants/common";
import ProductName from "../ProductName/ProductName";
import { useNavigate } from "react-router-dom";

const UserDataWrapper = styled.div`
  display: flex;
  gap: 8.4rem;
  .data-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .label {
      font-size: 1.2rem;
      font-weight: 400;
      color: var(--USER-DETAIL-LABEL);
    }
    .item {
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--PRIMARY);
      height: 2.9rem;
    }
  }
`;

const DataItem = ({ label, item }: { label: string; item: any }) => {
  return (
    <span className="data-item">
      <span className="label">{label}</span>
      <span className="item">{item}</span>
    </span>
  );
};

const UserData = ({
  type = "User",
  data,
}: {
  type?: string;
  data: IUserDetails;
}) => {
  const navigate = useNavigate();
  const isSuperAdmin = isSuperAdminUser();
  const isIndividual = isIndividualUser();
  const goToCustomerList = () => {
    !isIndividual && navigate("/home/customers");
  };

  return (
    <UserDataWrapper>
      <DataItem
        label={type === "User" || type === "Self" ? "Name" : `${type} Name`}
        item={capitalizeFirstLetter(data.name)}
      />
      {type === "User" ||
        (type === "Self" && <DataItem label="Email" item={data.email} />)}
      {type === "Self" && !isSuperAdmin && (
        <div
          className={!isIndividual ? "cursor-pointer" : ""}
          onClick={goToCustomerList}
        >
          <DataItem
            label={isIndividual ? "Customer" : "Customers"}
            item={
              data?.assigned_customers?.length &&
              data?.assigned_customers?.length > 0 ? (
                <ProductName
                  products={data.assigned_customers}
                  type={TABLE_LIST_TYPE.customer}
                />
              ) : (
                <span className="nil">-</span>
              )
            }
          />
        </div>
      )}
      <DataItem label="Status" item={<StatusTag content={data.status} />} />
      {(type === "User" || (type === "Self" && isSuperAdmin)) && (
        <DataItem
          label="Role"
          item={
            <StatusTag content={data.is_super_admin ? "Super admin" : "-"} />
          }
        />
      )}
    </UserDataWrapper>
  );
};

export default UserData;
