import { Timeline } from "antd";
import { ReactElement } from "react";

export interface ITimelineObject {
  id: number;
  label: string;
  children: string | ReactElement;
}

const ActivityTimeline = ({ data }: { data: ITimelineObject[] }) => {
  return <Timeline items={data} mode="left" />;
};

export default ActivityTimeline;
