import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserDetailReducer } from "../../interface/UserDetail";
import { GET, PATCH } from "../../service/api";

const initialState: IUserDetailReducer = {
  id: "",
  name: "",
  email: "",
  status: "",
  is_super_admin: false,
  assigned_customers: [],
  page_loader: true,
  table_loader: true,
  reload_table: false,
  page: 1,
  total_data: 0,
};

const UserDetailSlice = createSlice({
  name: "user_detail_reducer",
  initialState,
  reducers: {
    setPageLoader: (state, action: PayloadAction<boolean>) => {
      state.page_loader = action.payload;
    },
    updatePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
    resetUserDetailsReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("users/detail", "").fulfilled, (state, action) => {
      const { data } = action.payload;
      state.id = data.id;
      state.name = data.name;
      state.email = data.email;
      state.status = data.status;
      state.assigned_customers = data.assigned_customers;
      state.is_super_admin = data.is_super_admin;
      state.page_loader = false;
    });
    builder.addCase(GET("users/detail", "").pending, (state, action) => {
      state.page_loader = true;
    });
    builder.addCase(
      GET("users/assigned_customers", "").fulfilled,
      (state, action) => {
        const { results, total_count } = action.payload.data;
        state.assigned_customers = results?.length ? results : [];
        state.total_data = total_count;
        state.table_loader = false;
      }
    );
    builder.addCase(GET("users/assigned_customers", "").pending, (state) => {
      state.assigned_customers = [];
      state.table_loader = true;
      state.reload_table = false;
    });
    builder.addCase(PATCH("users/edit", "").fulfilled, (state, action) => {});
  },
});

export default UserDetailSlice.reducer;

export const { updatePage, reloadTable, resetUserDetailsReducer } =
  UserDetailSlice.actions;
