import { useEffect } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { useAppDispatch } from "./reduxHooks";
import { setAuthStatus } from "../store/features/Auth.slice";
import {
  checkUserInactivity,
  customerType,
  isIndividualUser,
} from "../utils/utils";
import store from "../store/store";
import { setCustomerType } from "../store/features/Common.slice";
import { ApiUrl } from "../utils/constants/ApiUrl";
import { GET } from "../service/api";

export const useAuth = () => {
  const { getItem } = useLocalStorage();
  const dispatch = useAppDispatch();
  const lastActive = getItem("last-active");
  const isAuth = getItem("isAuthenticated");
  const indvUser = isIndividualUser();

  useEffect(() => {
    let sessionExpired = false;
    if (lastActive) {
      sessionExpired = checkUserInactivity(lastActive);
    }
    if (isAuth && !sessionExpired) {
      dispatch(setAuthStatus(true));
    } else {
      dispatch(setAuthStatus(false));
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, lastActive]);

  useEffect(() => {
    //setting customer type --> prod only customers/ platform only customer/ both
    if (indvUser && isAuth) {
      const custType = customerType();
      store.dispatch(setCustomerType(custType));
      if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user") || "");
        const { customer } = user;
        _fetchVertexNodes(customer?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, indvUser, isAuth]);

  const _fetchVertexNodes = (id: string) => {
    dispatch(
      GET(
        "customerSpecific/customerNodes",
        `${ApiUrl.customerSpecificNodes}${id}/vertex-nodes/`
      )()
    );
  };
};
