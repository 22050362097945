import { Modal } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div``;

export const CustomerDetailsAddModal = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 0.3rem;
  }
  .ant-form-item-control-input-content {
    .ant-radio-group {
      display: flex !important;
      justify-content: space-between;
      .radio-items {
        gap: 0.6rem;
        align-items: center;
      }
      .ant-radio-button-wrapper {
        width: 27.5rem;
        height: 6.8rem;
        padding: 2.2rem 8.2rem !important;
        border: 1px solid var(--BORDER);
        border-radius: 1.2rem;
        display: flex !important;
        align-items: center !important;
        justify-content: center;
        color: var(--PRIMARY);
        font-size: 1.6rem;
        font-weight: 500;
        &:last-child {
          &::before {
            content: none;
          }
        }
      }
    }
  }
  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #d9d9d9;
    background: #ffffff;
    height: 5.3rem;
    // padding: 1rem 0.4rem;
  }
  .ant-radio-button-checked {
    border-radius: 1.2rem;
    border: 1px solid var(--PRIMARY);
    background: #f1f7ff;
  }
  .subtitle {
    display: block;
    font-size: 1.8rem;
    color: #142d50bf;
    margin-bottom: 2.4rem;
  }
  .user-assign-form {
    display: flex;
    justify-content: space-between;
  }
  .user-add-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    padding: 0 0.7rem;
    height: 3rem;
    margin: 0 0.5rem;
  }
  .form-item-user {
    width: 74%;
  }
  .form-item-role {
    width: 24%;
    // .ant-select-selection-item {
    //   color: var(--SECONDARY);
    // }
  }
`;
