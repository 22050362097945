import styled from "styled-components";

export const DetailsWrapper = styled.section`
  display: flex;
  flex: 1;
  gap: 2rem;
  .tree-section {
    height: calc(100vh - 15rem);
    overflow-x: auto;
    overflow-y: auto;
    flex-basis: 22%;
    border-top: 1px solid var(--BORDER);
    border-right: 1px solid var(--BORDER);
    padding: 1.8rem 0 0 1.8rem;
    ::-webkit-scrollbar-thumb {
      background: var(--PRIMARY);
      border-radius: 1rem;
    }
  }
  .file-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.2rem;
    .ant-breadcrumb li {
      color: var(--BREADCRUMB-ITEM1);
      font-size: 1.2rem;
    }
    .ant-breadcrumb li:last-child {
      color: var(--PRIMARY);
      font-weight: 500;
    }
    .action-btn {
      display: flex;
      justify-content: end;
    }
  }
`;
