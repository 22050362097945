import Documents from "../../Documents/Documents";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  setOpenAddFolderModal,
  updateCustomerDocPage,
} from "../../../store/features/CustomerDetails.slice";

const CustomerSpecificFiles = ({
  hasPermission,
}: {
  hasPermission: boolean;
}) => {
  const { customer_specific_files, doc_current_page, total_docs } =
    useAppSelector((state) => state.CustomerDetailsReducer);
  const { customer_active_node } = useAppSelector(
    (state) => state.FolderTreeReducer
  );
  const dispatch = useAppDispatch();
  //function on changing page
  const changePage = (pageNumber: number) => {
    dispatch(updateCustomerDocPage(pageNumber));
  };

  return (
    <>
      <Documents
        hasPermission={hasPermission}
        addFolder={() => dispatch(setOpenAddFolderModal(true))}
        docTitle={customer_active_node?.name}
        data={customer_specific_files}
        tableParams={{ page: doc_current_page, total_data: total_docs }}
        onDocPageChange={changePage}
      />
    </>
  );
};

export default CustomerSpecificFiles;
