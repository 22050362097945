import { createBrowserRouter, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
import Login from "../pages/Login/Login";
import Loader from "../components/Loader/Loader";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import PrivateRoute from "../utils/PrivateRoute";
import Error from "../components/Error/Error";
import { customerType, hasNoProducts, isIndividualUser } from "../utils/utils";
import LinkExpiry from "../components/LinkExpired/LinkExpiry";
import CreatePassword from "../pages/Signup/CreatePassword/CreatePassword";
import {
  SET_PASSWORD_CARD_TITLE,
  CUSTOMER_TYPES,
  USER_ROLES,
} from "../utils/constants/common";

const SigninLayout = React.lazy(() => import("../layout/SigninLayout"));
const LandingLayout = React.lazy(() => import("../layout/DashboardLayout"));
const Customer = React.lazy(() => import("../pages/Customer/Customer"));
const Products = React.lazy(() => import("../pages/Products/Products"));
const Users = React.lazy(() => import("../pages/User/User"));
const Signup = React.lazy(() => import("../pages/Signup/Signup"));
const BlockedIPs = React.lazy(() => import("../pages/BlockedIPs/BlockedIPs"));
const MyAccount = React.lazy(() => import("../pages/Dashboard/MyAccount"));
const ContactUs = React.lazy(() => import("../pages/ContactUs/ContactUs"));
const CustomerDetails = React.lazy(
  () => import("../pages/CustomerDetails/CustomerDetails")
);
const ProductDetail = React.lazy(
  () => import("../pages/ProductDetail/ProductDetail")
);
const UserDetail = React.lazy(() => import("../pages/UserDetail/UserDetail"));

const CustomerUserFolderTree = React.lazy(
  () => import("../pages/CustomerUserFolderTree/CustomerUserFolderTree")
);
const Notification = React.lazy(
  () => import("../pages/Notifications/Notification")
);

const IndexPage = () => {
  const indexpage =
    hasNoProducts() ||
    (isIndividualUser() &&
      (customerType() === CUSTOMER_TYPES.PROD ||
        customerType() === CUSTOMER_TYPES.NO_PROD))
      ? "products"
      : isIndividualUser() && customerType() === CUSTOMER_TYPES.PLATFORM
      ? "platforms"
      : "customers";
  return <Navigate to={indexpage} />;
};
const ResetPassword = React.lazy(
  () => import("../pages/ResetPassword/ResetPassword")
);
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <SigninLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "forgot-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password",
        element: (
          <CreatePassword title={SET_PASSWORD_CARD_TITLE.resetPassword} />
        ),
      },
    ],
    errorElement: <Error />,
  },
  {
    path: "activate/user",
    element: (
      <Suspense fallback={<Loader />}>
        <SigninLayout />
      </Suspense>
    ),
  },
  {
    path: "/home",
    element: (
      <Suspense fallback={<Loader />}>
        <PrivateRoute>
          <LandingLayout />
        </PrivateRoute>
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <IndexPage />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },

      {
        path: "products",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "products/detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <ProductDetail />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "platforms",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "platforms/detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <ProductDetail />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "documentation",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <CustomerUserFolderTree />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "designs",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <CustomerUserFolderTree />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "test_data",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <CustomerUserFolderTree />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={[USER_ROLES.SUPER_ADMIN]}>
              <Users />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "users/detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={[USER_ROLES.SUPER_ADMIN]}>
              <UserDetail />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "customers",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute
              roles={[
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.ADMIN,
                USER_ROLES.VIEWER,
              ]}
            >
              <Customer />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "customers/detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute
              roles={[
                USER_ROLES.SUPER_ADMIN,
                USER_ROLES.ADMIN,
                USER_ROLES.VIEWER,
              ]}
            >
              <CustomerDetails />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "blocked_ips",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={[USER_ROLES.SUPER_ADMIN]}>
              <BlockedIPs />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <Notification />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "contact_us",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={[USER_ROLES.INDIVIDUAL]}>
              <ContactUs />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "link-expired",
    element: <LinkExpiry />,
  },
  {
    path: "access-denied",
    element: <PageNotFound noAccess={true} />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);
