import { Table } from "antd";
import UploadButton from "../UploadButton/UploadButton";
import styled from "styled-components";
import { documentColumns } from "./DocumentsTableColumnData";
import { useAppSelector } from "../../hooks/reduxHooks";
import { LoaderLineComponent } from "../Loader/Loader";
import { IFile } from "../../interface/ProductDetails";
import { capitalizeFirstLetter } from "../../utils/utils";
import { ColumnsType } from "antd/es/table";
import TablePagination from "../TablePagination/TablePagination";
import { ANTD_TABLE_LOCALE } from "../../utils/constants/common";

const DocumentSection = styled.section`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: var(--PRIMARY);
      font-size: 2.4rem;
      font-weight: 500;
    }
  }
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-row:hover .actions {
    visibility: visible;
  }
  .pagination {
    width: 100%;
    justify-content: space-between;
    padding: 1.6rem 1rem 1.6rem 0rem;
    .item-count {
      color: var(--TABLE-HEADER);
    }
  }
`;

const Documents = ({
  docTitle,
  addFolder,
  data,
  columns = documentColumns,
  tableParams,
  onDocPageChange,
  hasPermission,
}: {
  docTitle: string;
  addFolder?: Function;
  data: IFile[];
  columns?: ColumnsType;
  tableParams: { page: number; total_data: number };
  onDocPageChange: Function;
  hasPermission: boolean;
}) => {
  const { document_loader } = useAppSelector(
    (state) => state.FolderTreeReducer
  );
  const loadIcon = <LoaderLineComponent />;

  return (
    <DocumentSection>
      <div className="header">
        <h3 className="title">{capitalizeFirstLetter(docTitle)}</h3>
        {hasPermission && <UploadButton addFolder={addFolder} />}
      </div>
      <Table
        locale={ANTD_TABLE_LOCALE}
        size="middle"
        pagination={false}
        rowKey="id"
        loading={{ indicator: loadIcon, spinning: document_loader }}
        dataSource={data}
        columns={columns}
      />
      <div className="pagination d-flex">
        <span className="item-count">
          Showing {data?.length} item(s) out of&nbsp; {tableParams?.total_data}{" "}
          results found.
        </span>

        <TablePagination
          total={tableParams?.total_data}
          onChange={(page) => onDocPageChange(page)}
          current={tableParams?.page}
        />
      </div>
    </DocumentSection>
  );
};

export default Documents;
