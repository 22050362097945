import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PATCH } from "../../service/api";
import { IDashboardReducer } from "../../interface/Dashboard";

const initialState: IDashboardReducer = {
  open_change_password_modal: false,
  open_update_2fa_modal: false,
  open_delete_account_modal: false,
};

const DashboardSlice = createSlice({
  name: "dashboard_reducer",
  initialState,
  reducers: {
    setOpenChangePasswordModal: (state, action: PayloadAction<boolean>) => {
      state.open_change_password_modal = action.payload;
    },
    setOpenUpdate2FAModal: (state, action: PayloadAction<boolean>) => {
      state.open_update_2fa_modal = action.payload;
    },
    setOpenDeleteAccountModal: (state, action: PayloadAction<boolean>) => {
      state.open_delete_account_modal = action.payload;
    },
    resetProfileReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      PATCH("profile/change-password", "").fulfilled,
      (state, action) => {}
    );
  },
});

export default DashboardSlice.reducer;

export const {
  setOpenChangePasswordModal,
  setOpenUpdate2FAModal,
  setOpenDeleteAccountModal,
  resetProfileReducer,
} = DashboardSlice.actions;
