import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomerReducer } from "../../interface/Customer";
import { GET, POST } from "../../service/api";

const initialState: ICustomerReducer = {
  customerTableParam: {
    page: 1,
    ordering: "",
  },
  customers_list: [],
  customer_table_loader: true,
  total_pages: 0,
  total_data: 0,
  search_customer_param: "",
  reload_table: false,
};
const CustomerSlice = createSlice({
  name: "customer_reducer",
  initialState,
  reducers: {
    setSearchParam: (state, action: PayloadAction<string>) => {
      state.search_customer_param = action.payload;
    },
    updateCustomerTableParam: (
      state,
      action: PayloadAction<{ page: number; ordering: string }>
    ) => {
      state.customerTableParam = action.payload;
    },
    reloadTable: (state, action: PayloadAction<boolean>) => {
      state.reload_table = action.payload;
    },
    setCustomerTableLoader: (state, action: PayloadAction<boolean>) => {
      state.customer_table_loader = action.payload;
    },
    resetCustomerReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(GET("customers/list", "").fulfilled, (state, action) => {
      const { results, total_count, total_pages } = action.payload.data;
      state.customers_list = results;
      state.total_pages = total_pages;
      state.total_data = total_count;
      state.customer_table_loader = false;
    });
    builder.addCase(GET("customers/list", "").rejected, (state, action) => {
      state.customers_list = [];
      state.total_data = 0;
      state.total_pages = 0;
      state.customer_table_loader = false;
    });
    builder.addCase(GET("customers/list", "").pending, (state, action) => {
      state.customer_table_loader = true;
      state.reload_table = false;
    });
    builder.addCase(POST("customers/add", "").fulfilled, (state, action) => {
      state.customer_table_loader = true;
      state.reload_table = false;
    });
  },
});
export default CustomerSlice.reducer;
export const {
  setSearchParam,
  updateCustomerTableParam,
  setCustomerTableLoader,
  reloadTable,
  resetCustomerReducer,
} = CustomerSlice.actions;
