import { Fragment } from "react/jsx-runtime";
import { INodes } from "../../interface/ProductDetails";
import { TreeWrapper } from "./TreeViewStyled";
import { TreeNode } from "./TreeNode/TreeNode";

const TreeView = ({
  nodes,
  id = "",
  name = "",
  isExpanded = false,
  isLoading = false,
  children = [],
  hasPermission = false,
  type = "",
}: {
  nodes: INodes;
  id?: string;
  name?: string;
  isExpanded?: boolean;
  isLoading?: boolean;
  children: INodes[];
  hasPermission?: boolean;
  type?: string;
}) => {
  return (
    <TreeWrapper>
      <TreeNode
        nodeItem={nodes}
        id={nodes?.id}
        name={nodes?.name}
        isExpanded={nodes?.expanded || false}
        children={children}
        isLoading={nodes?.loading || false}
        hasPermission={nodes?.hasPermission || false}
        type={type}
      />
      {children?.length > 0 &&
        nodes?.expanded &&
        children?.map((childNode: INodes) => (
          <Fragment key={childNode?.id}>
            {!childNode?.isDeleted && (
              <div className="sub-nodes" key={childNode?.id}>
                <TreeView
                  nodes={childNode}
                  isExpanded={childNode?.expanded}
                  id={childNode?.id}
                  name={childNode?.name}
                  children={childNode?.sub_nodes || []}
                  isLoading={childNode?.loading}
                  hasPermission={childNode?.hasPermission || false}
                  type={type}
                />
              </div>
            )}
          </Fragment>
        ))}
    </TreeWrapper>
  );
};

export default TreeView;
